import { createRequestUrl } from '../../../play-core/utils/request-url-helper';
export const LatestEpisodesByShowUrns = '/latest-episodes-by-show-urns';

// for better akamai caching only use one show once
export const loadLatestEpisodesByShowUrns = async (axios, showUrns, pageSize) => {
  const requestUrl = createRequestUrl(LatestEpisodesByShowUrns, {
    showUrns,
    pageSize
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};